.amplify-heading--3 {
  margin-bottom: 24px;
  text-align: center;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.amplify-visually-hidden {
  display: none;
}

.amplify-field + .amplify-field {
  margin-top: 24px;
}

/* Labels */
.amplify-label {
  display: block;
  margin-bottom: 5px;
}

/* Password field */
.amplify-passwordfield .amplify-field-group {
  position: relative;
}

.amplify-passwordfield .amplify-field-group__outer-end {
  position: absolute;
  top: 0;
  right: 0;
}

.amplify-passwordfield .amplify-field-group__outer-end .amplify-button {
  width: 60px;
  height: 40px;
  padding: 0;
  border-radius: 0 3px 3px 0;
}

.amplify-passwordfield .amplify-field-group__outer-end .amplify-button .amplify-icon {
  width: 24px !important;
  height: 22px !important;
}

/* Buttons */
.amplify-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 20px;
  line-height: inherit;
  color: #fff;
  font-family: 'gbold', 'Arial', sans-serif;
  text-decoration: none;
  overflow: hidden;
  border: 0;
  border-radius: 3px;
  background-color: #296495;
  transition: background-color 0.3s ease-out;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.amplify-button:hover {
  text-decoration: none;
  cursor: pointer;
  background-color: #235781;
}

.amplify-button--fullwidth {
  width: 100%;
  margin-top: 24px;
}

.amplify-button--loading > * {
  display: none;
}

.amplify-button--loading {
  height: 40px;
  font-size: 0;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NCA0NCIgc3Ryb2tlPSIjZmZmZmZmIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS13aWR0aD0iNCI+PGNpcmNsZSBjeD0iMjIiIGN5PSIyMiIgcj0iMSI+PGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iciIgYmVnaW49IjBzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMjAiIGNhbGNNb2RlPSJzcGxpbmUiIGtleVRpbWVzPSIwOyAxIiBrZXlTcGxpbmVzPSIwLjE2NSwgMC44NCwgMC40NCwgMSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+PGFuaW1hdGUgYXR0cmlidXRlTmFtZT0ic3Ryb2tlLW9wYWNpdHkiIGJlZ2luPSIwcyIgZHVyPSIxLjhzIiB2YWx1ZXM9IjE7IDAiIGNhbGNNb2RlPSJzcGxpbmUiIGtleVRpbWVzPSIwOyAxIiBrZXlTcGxpbmVzPSIwLjMsIDAuNjEsIDAuMzU1LCAxIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz48L2NpcmNsZT48Y2lyY2xlIGN4PSIyMiIgY3k9IjIyIiByPSIxIj48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJyIiBiZWdpbj0iLTAuOXMiIGR1cj0iMS44cyIgdmFsdWVzPSIxOyAyMCIgY2FsY01vZGU9InNwbGluZSIga2V5VGltZXM9IjA7IDEiIGtleVNwbGluZXM9IjAuMTY1LCAwLjg0LCAwLjQ0LCAxIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJzdHJva2Utb3BhY2l0eSIgYmVnaW49Ii0wLjlzIiBkdXI9IjEuOHMiIHZhbHVlcz0iMTsgMCIgY2FsY01vZGU9InNwbGluZSIga2V5VGltZXM9IjA7IDEiIGtleVNwbGluZXM9IjAuMywgMC42MSwgMC4zNTUsIDEiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPjwvY2lyY2xlPjwvZz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
}

/* Forgot password link */
[data-amplify-footer] {
  margin-top: 24px;
  text-align: center;
}

[data-amplify-footer] .amplify-button {
  color: #296495;
  font-family: 'gregular', 'Arial', sans-serif;
  background-color: transparent;
}

[data-amplify-footer] .amplify-button:hover {
  text-decoration: underline;
  background-color: transparent;
}

/* Error alert */
.amplify-alert,
[data-amplify-sign-up-errors] {
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
}

.amplify-alert--error,
[data-amplify-sign-up-errors] {
  background-color: #d44f4c;
}

[data-amplify-sign-up-errors] {
  padding: 10px;
}

.amplify-alert .amplify-icon {
  width: 24px !important;
  height: 24px !important;
}

.amplify-alert .amplify-alert__icon {
  margin-right: 10px;
}

.amplify-alert .amplify-alert__icon svg {
  fill: #d44f4c;
}

.amplify-alert .amplify-alert__dismiss {
  width: 40px;
  height: 40px;
  margin-top: 0 !important;
  background-color: transparent;
}

/* Reset password view */
[data-amplify-authenticator-resetpassword] fieldset > .amplify-flex:nth-of-type(2),
[data-amplify-authenticator-confirmresetpassword] fieldset > .amplify-flex:nth-of-type(2),
.amplify-alert + .amplify-flex {
  display: flex;
}

[data-amplify-authenticator-resetpassword] fieldset > .amplify-flex:nth-of-type(2) .amplify-button,
[data-amplify-authenticator-confirmresetpassword] fieldset > .amplify-flex:nth-of-type(2) .amplify-button,
.amplify-alert + .amplify-flex .amplify-button {
  margin-top: 24px;
}

/* Force new password view */
[data-amplify-authenticator-forcenewpassword] .amplify-button--primary,
[data-amplify-authenticator-forcenewpassword] .amplify-button--small {
  width: 100%;
  margin-top: 24px;
}
