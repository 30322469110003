* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html {
  height: 100%;
  color: #4B5358;
  font-size: 62.5%;
  line-height: 1.3;
}
body {
  font-size: 1.6rem;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loader--main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader--main svg {
  width: 100px;
  animation: loader--main 1.5s ease-in-out infinite both;
}
.loader--main h4 {
  position: relative;
  margin-top: 16px;
  font-family: 'Arial', sans-serif;
  font-size: 2rem;
}
.loader--main h4:before {
  content: attr(data-text);
  width: 0;
  position: absolute;
  color: #00b59c;
  overflow: hidden;
  white-space: nowrap;
  transition: width .5s;
  z-index: 2;
  animation: loader--text 1.5s ease-in-out infinite both;
}
@-webkit-keyframes loader--main {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes loader--text {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}